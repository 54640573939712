import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { getConfirmationPlanning, updateConfirmationPlanning } from '@/@core/queries/confirmation-planning'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

export default function useConfirmationPlanningEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const confirmationPlanning = ref({})
  const isSubmitting = ref(false)

  async function show() {
    isSubmitting.value = true
    store
      .dispatch('confirmationPlanningStoreModule/show', {
        query: getConfirmationPlanning,
        variables: { query: { _id: route.value.params.id } }
      })
      .then(({ data }) => {
        const confirmationPlanningData = data.data.confirmation_planning
        confirmationPlanning.value = {
          // role: confirmationPlanningData.role?._id,
          process: confirmationPlanningData.process?._id,
          confirmationsPerYear: confirmationPlanningData.confirmationsPerYear,
          evaluated: confirmationPlanningData.evaluated?._id,
        }
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.confirmation_planning_fetch_error'))
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }

  function update(data) {
    isSubmitting.value = true
    const payload = {
      // role: { link: data.role.value },
      process: { link: data.process.value },
      confirmationsPerYear: data.confirmationsPerYear.value,
      evaluated: { link: data.evaluated.value }
    }

    store
      .dispatch('confirmationPlanningStoreModule/update', {
        query: updateConfirmationPlanning,
        variables: { query: { _id: route.value.params.id }, data: payload },
      })
      .then(() => {
        showSuccessMessage(i18n.t('message.confirmation_planning_updated'))
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.confirmation_planning_update_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-confirmation-planning-list' })
        isSubmitting.value = false
      })
  }

  return {
    update,
    show,
    confirmationPlanning,
    isSubmitting,
  }
}
